.vaks-title-section {
  // border: red 1px solid;
  background: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)),
    url('../vaks/assets/images/vaks-landing-background.jpg') no-repeat center
      center;
  background-size: cover;
  position: relative;
  width: 100vw;
  left: calc(-50vw + 50%);
  display: flex;
  flex-direction: column;
  align-items: center;
  height: auto;
}
.text-line-height {
  line-height: 2.5;
}
#vaks-title {
  margin: 55px 100px;
  color: white;
  width: 40vw;
}
.vaks-food-relief {
  display: flex;
  align-items: center;
}
.vaks-food-relief-image {
  content: url('../vaks/assets/images/image-food-relief.jpg');
  width: 500px;
  margin: 0 100px;
}
.vaks-food-relief-text {
  line-height: 2.5;
}
.vaks-here-to-help {
  display: flex;
  align-items: center;
}
.vaks-here-to-help-text-wrapper {
  line-height: 2.5;
}
.vaks-here-to-help-image {
  content: url('../vaks/assets/images/here-to-help.jpg');
  width: 550px;
  height: 600px;
  object-fit: cover;
  margin: 80px;
}
.vaks-food-security-text-wrapper {
  margin-left: 50px;
}
.vaks-food-security {
  display: flex;
  align-items: center;
}
.vaks-food-security-image {
  content: url('../vaks/assets/images/food-security.jpg');
  width: 550px;
  height: 600px;
  object-fit: cover;
  margin: 80px;
}
.vaks-food-security-text-desc {
  line-height: 2.5;
}
.vaks-food-security-image-mobile {
  display: none;
}
.bottom-text {
  margin: 0px 50px;
}
@media only screen and (max-width: 600px) {
  .bottom-text {
    margin: 20px 0 0 0;
  }
  #vaks-title {
    margin: 10px 10px;
    width: 80vw;
  }
  .vaks-title-section {
    height: auto;
  }
  .vaks-food-relief {
    display: flex;
    flex-direction: column;
  }
  .vaks-food-relief-image {
    width: 100vw;
    margin: 10px 0 20px 0;
  }
  .vaks-food-security {
    flex-direction: column;
  }
  .vaks-food-security-image {
    display: none;
  }
  .vaks-food-security-image-mobile {
    content: url('../vaks/assets/images/food-security.jpg');
    width: 100vw;
    height: auto;
    object-fit: cover;
    margin: 40px;
    display: block;
  }
  .vaks-food-security-text-wrapper {
    margin-left: 0;
  }
  .vaks-here-to-help {
    flex-direction: column;
  }
  .vaks-here-to-help-image {
    width: 100vw;
    height: auto;
    margin: 40px;
  }
}
