.react-horizontal-scrolling-menu--wrapper {
  display: flex;
  flex-wrap: wrap;
  justify-content: start;
  overflow: auto;
}

.react-horizontal-scrolling-menu--scroll-container {
  max-height: 90px;
}

.react-horizontal-scrolling-menu--item {
  :hover {
    color: #7e63ab;
  }
}

::-webkit-scrollbar {
  background: #eaeaea;
  height: 8px;
  border-radius: 5px;
  transition: background-color 0.2s linear, width 0.2s ease-in-out,
    border-radius 0.2s ease-in-out;
  // box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
}

::-webkit-scrollbar-thumb {
  background-color: #aaa;
  border: 1px solid transparent;
  border-radius: 20px;
  width: 50px;
}
